import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        isSaved: false,
        isLoggedIn: false,
        isAdmin: false,
        expozanti: null,
        expozantiNeaprobati: null,
        numarCereriExpozanti: 0,
        expozantId: null,
        expozantNume: null,
        expozantTel: null,
        expozantCui: null,
        expozantEmail: null,
        expozantAdresa: null,
        expozantPersoanaContact: null,
        expozantBanca: null,
        expozantContBancar: null,
        expozantLogoUrl: null,
        expozantUserUrl: null,
        expozantWebsite: null,
        expozantIsApproved: null,
        expozantIsDisabled: null,
        evenimente: [],
        currentForm: null,
        centralizator: null,
        selectedExpozant: null,
        currentUser: null
    },
    getters: {
        getToken: state => state.token,
        getSavedState: state => state.isSaved,
        getLoggedIn: state => state.isLoggedIn,
        getNumarCereriExpozanti: state => state.numarCereriExpozanti,
        getIsAdmin: state => state.isAdmin,
        getExpozantiNeaprobati: state => state.expozantiNeaprobati,
        getExpozanti: state => state.expozanti,
        getExpozantId: state => state.expozantId,
        getExpozantNume: state => state.expozantNume,
        getExpozantTel: state => state.expozantTel,
        getExpozantCui: state => state.expozantCui,
        getExpozantEmail: state => state.expozantEmail,
        getExpozantAdresa: state => state.expozantAdresa,
        getExpozantPersoanaContact: state => state.expozantPersoanaContact,
        getExpozantBanca: state => state.expozantBanca,
        getExpozantContBancar: state => state.expozantContBancar,
        getExpozantLogoUrl: state => state.expozantLogoUrl,
        getExpozantUserUrl: state => state.expozantUserUrl,
        getExpozantWebsite: state => state.expozantWebsite,
        getExpozantIsApproved: state => state.expozantIsApproved,
        getExpozantIsDisabled: state => state.expozantIsDisabled,
        getEvenimente: state => state.evenimente,
        getCurrentForm: state => state.currentForm,
        getCentralizator: state => state.centralizator,
        getSelectedExpozant: state => state.selectedExpozant,
        getCurrentUser: state => state.currentUser
    },
    mutations: {
        setToken ( state, value ) {
            state.token = value
        },
        setSaved ( state, value ) {
            state.isSaved = value
        },
        setLoggedIn ( state, value ) {
            state.isLoggedIn = value
        },
        setNumarCereriExpozanti ( state, value ) {
            state.numarCereriExpozanti = value
        },
        setIsAdmin ( state, value ) {
            state.isAdmin = value
        },
        setExpozantiNeaprobati ( state, value ) {
            state.expozantiNeaprobati = value
        },
        setExpozanti ( state, value ) {
            state.expozanti = value
        },
        setExpozant ( state, value ) {
            state.expozant = value
        },
        setExpozantId ( state, value ) {
            state.expozantId = value
        },
        setExpozantNume ( state, value ) {
            state.expozantNume = value
        },
        setExpozantTel ( state, value ) {
            state.expozantTel = value
        },
        setExpozantCui ( state, value ) {
            state.expozantCui = value
        },
        setExpozantEmail ( state, value ) {
            state.expozantEmail = value
        },
        setExpozantAdresa ( state, value ) {
            state.expozantAdresa = value
        },
        setExpozantPersoanaContact ( state, value ) {
            state.expozantPersoanaContact = value
        },
        setExpozantBanca ( state, value ) {
            state.expozantBanca = value
        },
        setExpozantContBancar ( state, value ) {
            state.expozantContBancar = value
        },
        setExpozantLogoUrl ( state, value ) {
            state.expozantLogoUrl = value
        },
        setExpozantUserUrl ( state, value ) {
            state.expozantUserUrl = value
        },
        setExpozantWebsite ( state, value ) {
            state.expozantWebsite = value
        },
        setExpozantIsApproved ( state, value ) {
            state.expozantIsApproved = value
        },
        setExpozantIsDisabled ( state, value ) {
            state.expozantIsDisabled = value
        },
        setEvenimente ( state, value ) {
            state.evenimente = value
        },
        setCurrentForm ( state, value ) {
            state.currentForm = value
        },
        setCentralizator ( state, value ) {
            state.centralizator = value
        },
        setSelectedExpozant (state, value) {
            state.selectedExpozant = value
        },
        setCurrentUser (state, value) {
            state.currentUser = value
        }
    },
    actions: {
    },
    modules: {
    }
})
