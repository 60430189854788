<template>
	<v-form ref="form-contul-meu" @submit.prevent="prevSubmit">
        <v-container>
            <v-row>
                <v-col>
                    <v-toolbar flat outlined class="green lighten-5">
                        <v-toolbar-title>Completeaza sau modifica detaliile</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <div v-if="editing">
                            <v-btn
                                @click="anuleaza()"
                                rounded
                                color="primary"
                                dark
                                outlined
                                class="mr-3"
                            >
                                Anuleaza
                            </v-btn>

                            <v-btn
                                rounded
                                @click="updateUserFunction()"
                                :type="waiting ? 'button' : 'submit'"
                                outlined
                                :disabled="waiting"
                                :loading="waiting ? 'loading' : false"
                                color="primary"
                            >
                                Update
                            </v-btn>

                        </div>

                        <div v-else>
                            <v-btn
                                rounded
                                @click="editUser()"
                                type="button"
                                outlined
                                color="primary"
                                dark
                            >
                                Edit
                            </v-btn>
                        </div>
                    </v-toolbar>
                </v-col>
            </v-row>

            <v-row v-if="userObj">
                <v-col cols="12" sm="12" md="12" class="mb-4 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Logo
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="3" class="mb-0 pb-0">
                                    <v-card flat outlined class="mb-4">
                                        <v-card-text>
                                            <v-img v-if="!url" :src="apiPath + '/uploads/logos/' + userObj.logo_url" contain height="80" width="100%"></v-img>
                                            <v-img v-else :src="url" contain height="80" width="100%"></v-img>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" sm="12" md="9" class="mb-0 pb-0">
                                    <v-file-input 
                                        @change="previewImage"
                                        @click:clear="url = null"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        v-model="expozantLogo"
                                        label="Incarca logoul"
                                        class="mb-0"
                                        accept="image/*"
                                        required
                                    >
                                    </v-file-input>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mb-4 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Companie
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.nume_companie"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Numele companiei"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.cui"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="CUI"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.nr_reg_com"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Numar de inmatriculare la Registrul Comertului"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mb-4 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Banca
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.banca"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Banca"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.cont_bancar"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Cont bancar"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mb-4 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Contact
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="userObj.persoana_contact"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Persoana de contact"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">

                                    <v-text-field
                                        v-model="userObj.email"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Email"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">

                                    <v-text-field
                                        v-model="userObj.telefon"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Telefon"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">

                                    <v-text-field
                                        v-model="userObj.website"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Website"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4" class="mb-0 pb-0">

                                    <v-text-field
                                        v-model="userObj.fax"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Fax"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mb-4 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Adresa
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">
                                    <div class="text-subtitle-1 mb-2 font-weight-medium">
                                        Adresa sediu social:
                                    </div>

                                    <v-text-field
                                        v-model="userObj.sediu_localitate"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Localitatea"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.sediu_judet"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Judet/Sector"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.sediu_cod_postal"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Cod postal"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.sediu_adresa"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Adresa sediu social"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="6" class="mb-0 pb-0">
                                    <div class="text-subtitle-1 mb-2 font-weight-medium">
                                        Adresa de corespondenta (daca difera de cea a sediului social):
                                    </div>

                                    <v-text-field
                                        v-model="userObj.corespondente_localitate"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Localitatea"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.corespondente_judet"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Judet/Sector"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.corespondente_cod_postal"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Cod postal"
                                        class="mb-0"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="userObj.corespondente_adresa"
                                        dense
                                        outlined
                                        :disabled="!editing"
                                        label="Adresa sediu social"
                                        class="mb-0"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mb-0 py-0">
                    <v-card flat outlined>
                        <v-card-title>
                            Schimba parola
                        </v-card-title>
                        <v-card-text>
                            <div class="d-flex flex-row align-center gap-16">
                                <v-btn @click="editPassword" tile color="success" class="btn-40" :disabled="!editing" outlined>
                                    <v-icon v-if="passwordEditing" left>
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else left>
                                        mdi-pencil
                                    </v-icon>
                                    {{passwordEditing ? 'Anuleaza' : 'Schimba parola'}}
                                </v-btn>

                                <v-text-field
                                    v-model="expozantParola1"
                                    :append-icon="showExpozantParola1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    dense
                                    :type="showExpozantParola1 ? 'text' : 'password'"
                                    outlined
                                    :disabled="!passwordEditing"
                                    label="Parola"
                                    class="mb-0"
                                    required
                                    hide-details
                                    @click:append="showExpozantParola1 = !showExpozantParola1"
                                ></v-text-field>

                                <v-text-field
                                    v-model="expozantParola2"
                                    :append-icon="showExpozantParola2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    dense
                                    :type="showExpozantParola2 ? 'text' : 'password'"
                                    outlined
                                    :disabled="!passwordEditing"
                                    label="Confirmare parola"
                                    class="mb-0"
                                    hide-details
                                    @click:append="showExpozantParola2 = !showExpozantParola2"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="text-right">
                    <div v-if="editing">
                        <v-btn
                            @click="anuleaza()"
                            rounded
                            color="primary"
                            dark
                            outlined
                            class="mr-3"
                        >
                            Anuleaza
                        </v-btn>

                        <v-btn
                            rounded
                            @click="updateUserFunction()"
                            :type="waiting ? 'button' : 'submit'"
                            outlined
                            :disabled="waiting"
                            :loading="waiting ? 'loading' : false"
                            color="primary"
                        >
                            Update
                        </v-btn>

                    </div>

                    <div v-else>
                        <v-btn
                            rounded
                            @click="editUser()"
                            type="button"
                            outlined
                            color="primary"
                            dark
                        >
                            Edit
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import api from '../mixins/api'

	export default {
        mixins: [ api ],
        emits: ['updateUser', 'userUpdated', 'cancelForm'],
		name: 'UserForm',
        props: {
            user: {
                type: Object,
                required: true
            },
            enableEdit: {
                type: Boolean,
                required: false
            }
        },
		data: () => ({
            editing: false,
            passwordEditing: false,
            expozantParola1: null,
            expozantParola2: null,
            expozantLogo: null,
            url: null,
            waiting: null,
            showExpozantParola1: false,
            showExpozantParola2: false,
            userObj: null
		}),
		created () {
            console.log('currentUser', this.currentUser);
            this.editing = this.enableEdit;
            if (this.editing) {
                this.backupUser = JSON.parse(JSON.stringify(this.user));
            }
            this.userObj = this.user;
            
			this.$root.$on( 'updateUser', response => {
                this.snackbarUpdateUser = true;
                this.snackbarUpdateUserResponseText = response.message;
                this.snackbarUpdateUserColor = response.status ? 'primary lighten-5' : 'red lighten-5';
                if (response.status == 1) {
                    this.editing = false;
                    this.passwordEditing = false;
                    this.expozantParola1 = null;
                    this.expozantParola2 = null;
                }
                this.waiting = false;

                this.$emit( 'userUpdated' );
            } )
		},
        mounted () {

        },
		methods: {
			updateUserFunction () {
	            this.waiting = true;

	            const formData = new FormData();
	            formData.append('token', this.token);
	            formData.append('nume_companie', this.userObj.nume_companie);
	            formData.append('cui', this.userObj.cui);
	            formData.append('nr_reg_com', this.userObj.nr_reg_com);
	            formData.append('banca', this.userObj.banca);
	            formData.append('cont_bancar', this.userObj.cont_bancar);
	            formData.append('persoana_contact', this.userObj.persoana_contact);
	            formData.append('email', this.userObj.email);
	            formData.append('telefon', this.userObj.telefon);
	            formData.append('website', this.userObj.website);
	            formData.append('fax', this.userObj.fax);
	            formData.append('sediu_localitate', this.userObj.sediu_localitate);
	            formData.append('sediu_judet', this.userObj.sediu_judet);
	            formData.append('sediu_cod_postal', this.userObj.sediu_cod_postal);
	            formData.append('sediu_adresa', this.userObj.sediu_adresa);
	            formData.append('corespondente_localitate', this.userObj.corespondente_localitate);
	            formData.append('corespondente_judet', this.userObj.corespondente_judet);
	            formData.append('corespondente_cod_postal', this.userObj.corespondente_cod_postal);
	            formData.append('corespondente_adresa', this.userObj.corespondente_adresa);
	            formData.append('logo', this.expozantLogo);

	            if (this.passwordEditing) {
	                formData.append('pass1', this.expozantParola1);
	                formData.append('pass2', this.expozantParola2);
	            }
	            // this.updateUser(formData);
                this.$emit( 'updateUser', formData );
	        },
	        editUser () {
	            this.backupUser = JSON.parse(JSON.stringify(this.userObj));
	            this.editing = true;
	        },
	        anuleaza () {
	            this.userObj = JSON.parse(JSON.stringify(this.backupUser));
	            this.editing = false;
	            this.expozantParola1 = null;
	            this.expozantParola2 = null;
	            this.passwordEditing = false;
	            this.expozantLogo = null;
	            this.url = null;
                this.$emit( 'cancelForm' );
	        },
	        editPassword () {
	            this.passwordEditing = !this.passwordEditing;
	            this.expozantParola1 = null;
	            this.expozantParola2 = null;
	        },
	        prevSubmit () {
	            if (!this.editing) return;
	        },
            previewImage(e) {
                if (e instanceof Array && e.length === 0) return;
                if (!e) return;
                this.$nextTick(() => {
                    this.url = URL.createObjectURL(e);
                });
            }
		}
	}
</script>