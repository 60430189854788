<template>
    <v-container>
        <v-row dense>
            <v-col v-if="currentUser">
                <v-card flat color="#F4F7F4">
                    <v-card-title>
                        Contul meu (expozant #{{expozantId}})
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-sheet class="pa-4">
                            <v-tabs v-model="contTabs" color="primary" left>
                                <v-tab>Detalii cont</v-tab>
                                <v-tab>Contract</v-tab>
                                <v-tab-item class="pt-4">
                                    <UserForm v-if="currentUser?.email" @updateUser="updateUser" @userUpdated="userUpdated" :user="currentUser"></UserForm>
                                </v-tab-item>
                                <v-tab-item class="pt-4">
                                    <v-stepper flat v-model="step">
                                        <v-stepper-header class="elevation-0">
                                            <v-stepper-step :complete="step > 1" step="1">
                                                Descarca contractul
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step :complete="step > 2" step="2">
                                                Incarca contractu semnat
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step step="3">
                                                Procesare
                                            </v-stepper-step>
                                        </v-stepper-header>
                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <v-card class="mb-12" flat>
                                                    <Contract></Contract>
                                                </v-card>
                                                <v-btn color="primary" text @click="step = 2">
                                                    Pasul urmator
                                                </v-btn>
                                            </v-stepper-content>
                                            <v-stepper-content step="2">
                                                <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>
                                                <v-btn outlined class="mr-4" color="primary" @click="step = 1">
                                                    Inapoi
                                                </v-btn>
                                                <v-btn color="primary" @click="step = 3">
                                                    Trimite contractul
                                                </v-btn>
                                            </v-stepper-content>
                                            <v-stepper-content step="3">
                                                <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>
                                                <v-btn color="primary" @click="step = 1">
                                                    Continue
                                                </v-btn>
                                                <v-btn text>
                                                    Cancel
                                                </v-btn>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                    <!-- <v-window v-model="step">
                                            <v-window-item :value="1">
                                                <v-card-title>
                                                    Pasul 1: Descarca contract
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-btn
                                                        @click="descarcaContract"
                                                        rounded
                                                        outlined
                                                        class="mr-3"
                                                    >
                                                        Descarca contract
                                                        <v-icon right>
                                                            mdi-download
                                                        </v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                                <v-card-actions>
                                                    next
                                                </v-card-actions>
                                            </v-window-item>

                                                <v-window-item :value="2">
        <v-card-text>
          <v-text-field
            label="Password"
            type="password"
          ></v-text-field>
          <v-text-field
            label="Confirm Password"
            type="password"
          ></v-text-field>
          <span class="text-caption grey--text text--darken-1">
            Please enter a password for your account
          </span>
        </v-card-text>
      </v-window-item>
  </v-window> -->
                                    <!-- </v-card> -->
                                    <!-- <v-btn
                                        @click="descarcaContract"
                                        rounded
                                        outlined
                                        class="mr-3"
                                    >
                                        Descarca contract
                                    </v-btn>

                                    <v-form @submit.prevent="incarcaContract">
                                        <v-file-input 
                                            dense
                                            outlined
                                            v-model="contract"
                                            label="Contract"
                                            class="mb-0"
                                            accept="pdf"
                                            required
                                        >
                                        </v-file-input>
                                        <v-btn
                                            rounded
                                            outlined
                                            type="submit"
                                        >
                                            Incarca contract
                                        </v-btn>
                                    </v-form> -->
                                </v-tab-item>
                            </v-tabs>
                        </v-sheet>
                    </v-card-text>
                </v-card>
                <v-snackbar v-model="snackbarUpdateCurrentUser">
                    {{ snackbarUpdateCurrentUserResponseText }}
                    <template v-slot:action="{ attrs }">
                        <v-btn :color="snackbarUpdateCurrentUserColor" text v-bind="attrs" @click="snackbarUpdateCurrentUser = false">
                            OK
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import api from '../mixins/api'
import Contract from '../components/Contract'
import UserForm from '../components/UserForm'

export default {
    mixins: [api],
    components: {
        Contract,
        UserForm
    },
    data: () => ({
        contTabs: 0,
        step: 1,
        editing: false,
        edit: false,
        passwordEditing: false,
        expozantParola1: null,
        expozantParola2: null,
        expozantLogo: null,
        url: null,
        backupCurrentUser: null,
        snackbarUpdateCurrentUser: false,
        snackbarUpdateCurrentUserColor: 'primary',
        snackbarUpdateCurrentUserResponseText: '',
        contract: null,
        waiting: null,
        showExpozantParola1: false,
        showExpozantParola2: false
    }),
    created() {
        this.getExpozant(this.token);
        this.getCurrentUser(this.token);
    },
    methods: {
        descarcaContract() {

        },
        incarcaContract() {
            const formData = new FormData();
            formData.append('token', this.token);
            formData.append('id_expozant', this.expozantId);
            formData.append('contract', this.contract);
            this.uploadContract(formData, this.token);
        },
        userUpdated() {
            console.log('userUpdated');

            const formData = new FormData();

            formData.append('logo', this.expozant.logo);
            formData.append('adresa', this.expozant.adresa);
            formData.append('banca', this.expozant.banca);
            formData.append('cont_bancar', this.expozant.cont_bancar);
            formData.append('cui', this.expozant.cui);
            formData.append('email', this.expozant.email);
            formData.append('nr_tel', this.expozant.nr_tel);
            formData.append('nume', this.expozant.nume);
            formData.append('persoana_contact', this.expozant.persoana_contact);
            formData.append('website', this.expozant.website);
            formData.append('parola_1', this.expozant.parola_1);
            formData.append('parola_2', this.expozant.parola_2);

            const url = this.apiPath + 'update-expozant.php';

            axios.post(url, formData).then(response => {
                if (response.data.status === 1) {
                    // this.$router.push({ name: 'pending' });
                } else {
                    console.log(response.data)
                }
            });
        }
    }
}
</script>
<style>
.elev-0 .v-expansion-panel::before {
    box-shadow: none !important;
}

.btn-40 {
    height: 40px !important;
}
</style>