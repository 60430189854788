<template>
    <v-container>
        <v-app-bar flat>
            <v-btn text>Inapoi la evenimente</v-btn>
        </v-app-bar>

        <v-row dense v-if="isAdmin">
            <v-col>

                <v-card flat color="#F4F7F4">
                    <v-card-title>
                        Centralizator formular x

                        <v-spacer></v-spacer>

                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Cauta expozant"
                            single-line
                            hide-details
                        ></v-text-field>

                        <v-spacer></v-spacer>
                        
                    </v-card-title>

                    <v-card-text>
                        <v-data-table
                            v-if="centralizator"
                            :headers="headers"
                            :items="centralizator.formulare"
                            :search="search"
                        >
                            <template v-slot:item.logo="{ item }">
                                <v-sheet width="96" class="pa-2" color="transparent">
                                    <v-img :src="`https://evenimente.dev-factory.ro/${item.logo_url}`" contain :aspect-ratio="1/1"></v-img>
                                </v-sheet>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <v-sheet color="transparent" v-if="item.status == 1">Partial</v-sheet>
                                <v-sheet color="transparent" v-if="item.status == 2">Completat</v-sheet>
                            </template>

        <!--                    <template v-slot:item.link="{ item }">

                                <v-btn :to="{ name: 'expozant', params: {slugExpozant: item.user_url}}" color="primary" text>
                                    <v-icon>mdi-open-in-app</v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:item.adresa="{ item }">
                                {{item.adresa}}
                            </template> -->
                        </v-data-table>
                    </v-card-text>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import api from '../mixins/api'

export default {
    mixins: [ api ],
    components: {
    },
    data: () => ({
        search: '',
        headers: [
            { text: 'Logo', value: 'logo' },
            { text: 'Nume companie', value: 'nume_expozant' },
            { text: 'Status', value: 'status' }
        ],
    }),
    created () {
        console.log(this.$route.params)
        this.getCentralizator(this.$route.params.idFormular, 1, this.token);

        this.$root.$on( 'centralizator', () => {
            this.centralizator.atribute.forEach(attr => {

                this.headers.push({text: attr.nume, value: attr.value == 'constructor' ? 'constructor-event' : attr.value})
            });

            console.log(this.headers)
        } )

        
    }
}

</script>

<style>
    .centralizator-screen {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #F4F7F4;
    }
</style>