import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import PendingView from '../views/PendingView.vue'
import DashboardView from '../views/DashboardView.vue'
import CereriView from '../views/CereriView.vue'
import FormsView from '../views/FormsView.vue'
import ContView from '../views/ContView.vue'
import EvenimenteView from '../views/EvenimenteView.vue'
import CompleteazaFormularView from '../views/CompleteazaFormularView.vue'
import CentralizatorView from '../views/CentralizatorView.vue'



Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'login',
        component: LoginView
    }, {
        path: '/register',
        name: 'register',
        component: RegisterView
    }, {
        path: '/pending',
        name: 'pending',
        component: PendingView
    }, {
        path: '/cont',
        name: 'cont',
        component: ContView
    }, {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
    }, {
        path: '/cereri',
        name: 'cereri',
        component: CereriView
    }, {
        path: '/evenimente',
        name: 'evenimente',
        component: EvenimenteView
    }, {
        path: '/eveniment/:slugEvent/:slugExpozant/:idFormular',
        name: 'completeazaFormular',
        component: CompleteazaFormularView
    }, {
        path: '/centralizator/:slugEvent/:idFormular',
        name: 'centralizator',
        component: CentralizatorView
    }, {
        path: '/expozanti',
        name: 'expozanti',
        component: () => import(/* webpackChunkName: "about" */ '../views/ExpozantiView.vue')
    }, {
        path: '/expozant/:slugExpozant',
        name: 'expozant',
        component: () => import(/* webpackChunkName: "about" */ '../views/ExpozantView.vue')
    }, {
        path: '/expozant/:slugExpozant/formular-1',
        name: 'formular',
        component: () => import(/* webpackChunkName: "about" */ '../views/FormularView.vue')
    }, {
        path: '/expozant/:slugExpozant/formular-3-6',
        name: 'formular-3-6',
        component: () => import(/* webpackChunkName: "about" */ '../views/Formular36View.vue')
    }, {
        path: '/formulare',
        name: 'formulare',
        component: FormsView,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
