<template>
    <v-card flat color="#F4F7F4" class="register-container">
        <v-card-text>
            <v-form
                v-model="valid"
                ref="form"
                @submit.prevent="saveExpozant"
            >
                <v-flex class="d-flex justify-center align-center min-height-100vh">
                    <div>
                        <v-card flat max-width="500px">
                            <v-card-title class="mb-4">
                                <v-sheet class="d-flex flex-column justify-center align-center width-full pt-2">
                                    <v-img v-if="!url" src="../assets/logo-placeholder.svg" contain height="80" width="80"></v-img>
                                    <v-img v-else :src="url" height="80" width="80" contain></v-img>
                                    <div class="text-center">
                                        <div class="text-h5 text-center">Adauga expozant</div>
                                    </div>
                                </v-sheet>
                            </v-card-title>

                            <v-card-text class="pb-0">
                            
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">

                                            <v-file-input 
                                                @change="previewImage"
                                                dense
                                                outlined
                                                v-model="expozant.logo"
                                                label="Incarca logoul"
                                                class="mb-0"
                                                accept="image/*"
                                                required
                                            >
                                            </v-file-input>
                                            
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0 pb-0">
                                            <v-text-field
                                                v-model="expozant.nume"
                                                dense
                                                outlined
                                                label="Numele companiei"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.persoana_contact"
                                                dense
                                                outlined
                                                label="Persoana de contact"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.email"
                                                dense
                                                outlined
                                                label="Email"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>


                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.nr_tel"
                                                dense
                                                outlined
                                                label="Telefon"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>


                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.cui"
                                                dense
                                                outlined
                                                label="CUI"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.adresa"
                                                dense
                                                outlined
                                                label="Adresa"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.banca"
                                                dense
                                                outlined
                                                label="Banca"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                                v-model="expozant.cont_bancar"
                                                dense
                                                outlined
                                                label="Cont bancar"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 pt-0 pb-0">
                                            <v-text-field
                                                v-model="expozant.website"
                                                dense
                                                outlined
                                                label="Website"
                                                class="mb-0"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                            type="password"
                                                v-model="expozant.parola_1"
                                                dense
                                                outlined
                                                label="Parola"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="6" class="mb-0 py-0">
                                            <v-text-field
                                            type="password"
                                                v-model="expozant.parola_2"
                                                dense
                                                outlined
                                                label="Repeta parola"
                                                class="mb-0"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="pb-4 px-8 text-center">
                                <v-flex justify-center>
                                    <v-btn type="submit" color="primary" dark rounded>înregistrare</v-btn>
                                </v-flex>
                            </v-card-actions>

                            <div class="pa-4 text-center">
                                Esti deja expozant?
                                <router-link :to="{name: 'login'}">Loghează-te!</router-link>
                            </div>
                        </v-card>
                    </div>
                </v-flex>
            </v-form>
            
        </v-card-text>
    </v-card>
</template>

<script>

import axios from 'axios'

import api from '../mixins/api'

export default {
    name: 'LoginView',
    mixins: [ api ],
    data: () => ({
        expozant: {
            url: null,
            logo: null,
            nume: null,
            persoana_contact: null,
            email: null,
            nr_tel: null,
            cui: null,
            adresa: null,
            banca: null,
            cont_bancar: null,
            website: null,
            parola_1: null,
            parola_2: null
        },
        url: null,
        valid: null,
        email: null,
        password: null
    }),
    created () {
    },
    mounted () {
    },
    methods: {
        previewImage(e) {
            if (e instanceof Array && e.length === 0) return;
            if (!e) return;
            this.$nextTick(() => {
                this.url = URL.createObjectURL(e);
            });
        },
        saveExpozant (event) {
            console.log('dddd')
            const formData = new FormData();

            formData.append('logo', this.expozant.logo);
            formData.append('adresa', this.expozant.adresa);
            formData.append('banca', this.expozant.banca);
            formData.append('cont_bancar', this.expozant.cont_bancar);
            formData.append('cui', this.expozant.cui);
            formData.append('email', this.expozant.email);
            formData.append('nr_tel', this.expozant.nr_tel);
            formData.append('nume', this.expozant.nume);
            formData.append('persoana_contact', this.expozant.persoana_contact);
            formData.append('website', this.expozant.website);
            formData.append('parola_1', this.expozant.parola_1);
            formData.append('parola_2', this.expozant.parola_2);

            const url = this.apiPath + 'save-expozant.php';

            axios.post(url, formData).then(response => {
                if (response.data.status === 1) {
                    this.$router.push({ name: 'pending' });
                } else {
                    console.log(response.data)
                }
            });
        }
    }
}
</script>

<style>
    .register-container {
        min-height: 100vh;
    }

    .min-height-100vh {
        min-height: 100vh;
    }
</style>