<template>

    <div>
        <div v-if="currentUser" class="contract">

            <ol id="contract" class="contract-counter" style="width: 874px; padding: 16px;">
                <li>
                    <h6 class="text-h6">Parti</h6>
                    <ol>
                        <li><p><b>DLG InterMarketing S.R.L.</b>, cu sediul social in <b>B-dul. Constantin Brancoveanu, Nr. 6, Bl. B1, Sc. 2, Et. 3, Ap. 42, Sector 4, Bucuresti</b> si punct de lucru (adresa de corespondenta) in <b>Str. Dionisie Lupu, Nr. 50, Et. 1, Ap. 4, Sector 1</b>, Cod Postal <b>010458</b>, <b>Bucuresti</b>, Nr. Reg. Comertului: <b>J40/13609/2002</b>, CUI: <b>RO 15105544</b>, Tel.: <b>+40 21 317 12 25 / 26</b>, Fax: <b>+40 21 319 63 71</b>; Web: <b>www.agraria-dlg.ro</b>; e-mail: <b>r.mares@dlg.org</b>; reprezentata prin <b>Raluca Mares</b>, Director Evenimente ("DLG" sau "Organizatorul"),</p></li>
                        <li>
                            <p><b>{{currentUser.nume_companie}}</b>, cu sediul social in localitatea: <b>{{currentUser.sediu_localitate}}</b>, Judet / Sector: <b>{{currentUser.sediu_judet}}</b>, Cod Postal: <b>{{currentUser.sediu_cod_postal}}</b>, adresa sediu social: <b>{{currentUser.sediu_adresa}}</b>, adresa de corespondenta (daca difera de cea a sediului social): <b>{{currentUser.corespondente_adresa}}</b>, Telefon: <b>{{currentUser.telefon}}</b>, Fax: <b>{{currentUser.fax}}</b>, Cod fiscal: <b>{{currentUser.cui}}</b>, Nr. Reg. Comertului: <b>{{currentUser.nr_reg_com}}</b>, Cont IBAN: <b>{{currentUser.cont_bancar}}</b>, Nume banca si sucursala: <b>{{currentUser.banca}}</b>, reprezentata prin: <b>{{currentUser.persoana_contact}}</b>, Director General ("Expozantul"), denumite in continuare impreuna "Partile" si fiecare "Partea" au incheiat prezentul Contract de Servicii ("Contractul"), dupa cum urmeaza:</p>
                            <p><b>Definitii</b> (cu exceptia cazului in care contextul indica altfel, termenii definiti mai jos vor avea urmatoarele semnificatii):</p>
                            <ul>
                                <li><b>"Spatiu expozitional"</b> - perimetrul in cadrul caruia se desfasoara Expozitia</li>
                                <li><b>"Expozitie"</b> - Agraria 2023 - Targ International pentru Agricultura, Industrie Alimentara si Zootehnie</li>
                                <li><b>"Expozant"</b> - firma care participa la Expozitia Agraria 2023</li>
                                <li><b>"Persoana de contact"</b> Expozant - Persoana de contact comunicata in Formularul de Inscriere F1</li>
                            </ul>
                        </li>
                    </ol>
                </li>
            </ol>

            
        
        </div>
        <v-btn
                @click="descarcaContractul()"
                color="primary"
                class="mt-4"
            >
                Descarca contractul
                <v-icon right>
                    mdi-download
                </v-icon>
            </v-btn>
    </div>

</template>

<script>
    import api from '../mixins/api'
    import { jsPDF } from "jspdf";

    export default {
        name: 'Contract',
        mixins: [ api ],
        data: () => ({
        }),
        mounted () {
            
        },
        methods: {
            descarcaContractul () {
                const doc = new jsPDF({
                    orientation: "portrait",
                    unit: "px",
                    format: [874, 1240],
                    hotfixes: ["px_scaling"]
                })

                doc.html(document.getElementById('contract'), {
                    callback: doc => {
                        doc.save('contract');
                    }
                });
            }
        }
    }
</script>

<style>
    .contract {
        max-height: 480px;
        overflow-y: scroll;
        background-color: #FFFFFF;
    }
/*    .contract-counter {
  counter-reset: item
}
.contract-counter li {
  display: block
}
.contract-counter li:before {
  content: counters(item, ".") " ";
  counter-increment: item
}*/

ol {
    counter-reset: item;
}

ol:before {
    font-weight: bold;
}


ol > li {
    counter-increment: item;
}

ol ol > li {
    display: block;
}

ol ol > li:before {
    font-weight: bold;
    content: counters(item, ".") ". ";
    margin-left: -24px;
}
</style>