<template>
	<div>
		<v-dialog
			v-model="dialogDeviz"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			scrollable
		>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-btn icon dark @click="dialogDeviz = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Genereaza deviz pentru x</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text @click="exportToPDF">
							Genereaza
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text>
					<generatorPDF v-if="currentForm" :form="currentForm" />
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-container>
	        <v-row v-if="evenimente" dense>
	            <v-col v-for="eveniment in evenimente" cols="12">
	            	<v-card flat color="#F4F7F4">
						<!-- <v-img :src="eveniment.img_url" :aspect-ratio="2/1"></v-img> -->

						<v-card-title>{{eveniment.nume}}</v-card-title>

						<v-card-text>
							<v-divider></v-divider>
							<v-timeline align-top dense>
	            				<v-timeline-item
	              					v-for="(formular, index) in eveniment.formulare"
	              					:key="index"
	              					:color="getColor(formular.status)"
	              					:icon="getIcon(formular.status)"
	            					fill-dot
	            				>
	              					<div>
	                					<div>
	                						<router-link :to="{ name: 'completeazaFormular', params: {slugEvent: eveniment.slugEvent, slugExpozant: eveniment.user_url, idFormular: formular.id}}"><strong>Completeaza {{formular.nume_formular}}</strong></router-link>
	                					</div>
	                					<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
			                					<v-btn
			                						v-if="isAdmin"
			                						v-bind="attrs"
													v-on="on"
			                						:to="{ name: 'centralizator', params: {slugEvent: eveniment.slugEvent, idFormular: formular.id}}"
													icon
												>
													<v-icon>mdi-database</v-icon>
												</v-btn>
											</template>
											<span>Centralizator</span>
										</v-tooltip>

										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													v-if="formular.status == 2"
													v-bind="attrs"
													v-on="on"
			                						@click="openDialogDeviz(formular.id)"
													icon
												>
													<v-icon>mdi-file-pdf-box</v-icon>
												</v-btn>
											</template>
											<span>Genereaza deviz</span>
										</v-tooltip>

	                					<!-- <div v-if="isAdmin">
	                						<v-chip
												class="mt-2 mr-2"
												color="primary"
												dark
												small
											>
	                							<router-link class="white--text text-decoration-none" :to="{ name: 'centralizator', params: {slugEvent: eveniment.slugEvent, idFormular: formular.id}}">Centralizator</router-link>
	                						</v-chip>
	                						<v-chip
	                							v-if="formular.status == 2"
	                							@click="openDialogDeviz(formular.id)"
												class="mt-2 mr-2"
												color="primary"
												small
											>
												Genereaza deviz
											</v-chip>
	                					</div> -->
	            					</div>
	        					</v-timeline-item>
	        				</v-timeline>
	        				<v-divider></v-divider>

	        				<v-sheet class="d-flex align-right justify-end pt-3 transparent">

							</v-sheet>



	        				<!-- <v-chip
								class="mt-2 mr-2"
								color="primary"
								small
							>
								Genereaza deviz - toate formularele
							</v-chip> -->
						</v-card-text>
					</v-card>
	            </v-col>

	        </v-row>
	    </v-container>
	</div>
</template>

<script>

import GeneratorPDF from '../components/GeneratorPDF'
import axios from 'axios'
import api from '../mixins/api'

export default {
	components: {
		GeneratorPDF
	},
	mixins: [ api ],
    data: () => ({
    	form: null,
    	dialogDeviz: false
    }),
    created () {
    	this.setEvenimente(this.token);
    },
    computed: {
		icon () {
			return 'mdi-star-half-full'
		}
    },
    methods: {
    	openDialogDeviz (id) {
    		this.dialogDeviz = true
    		this.getForm(id, this.token);
    	},
    	getColor (status) {
    		let output = '';
    		if (status == 0) {
    			output = 'grey'
    		} else if (status == 1) {
    			output = 'orange'
    		} else {
    			output = 'green'
    		}
    		return output;

    	},
    	getIcon (status) {
    		let output = '';
    		if (status == 0) {
    			output = 'mdi-star-outline';
    		} else if (status == 1) {
    			output = 'mdi-star-half-full';
    		} else {
    			output = 'mdi-star';
    		}
    		return output;

    	},
    	exportToPDF () {
    		this.$root.$emit( 'exportToPDF' );
    	}
    }
  }
</script>

<style>
	
</style>