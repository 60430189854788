<template>
    <div class="login-screen">
        DASHBOARD
    </div>
</template>

<script>

import axios from 'axios'
import api from '../mixins/api'

export default {
    name: 'DashboardView',
    mixins: [ api ],
    data: () => ({
    }),
    created () {
    },
    mounted () {
    },
    methods: {
    }
}
</script>

<style>
    .login-screen {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>