<template>
    <v-app id="app">

        <v-navigation-drawer v-if="isLoggedIn" permanent app>
            <div class="px-12 py-4">
                <v-img
                    :aspect-ratio="1"
                    src="./assets/logo-dlg.svg"
                ></v-img>
            </div>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">Administrare formulare</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
                <v-list-item link :to="{name: 'dashboard'}">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{name: 'cont'}">
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Contul meu</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{name: 'evenimente'}">
                    <v-list-item-icon>
                        <v-icon>mdi-calendar</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Evenimente</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{name: 'cereri'}" v-if="isAdmin && expozantiNeaprobati">
                    <v-list-item-icon>
                        <v-icon>mdi-clipboard-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-badge
                            :content="expozantiNeaprobati.length"
                            :value="expozantiNeaprobati.length"
                            color="green"
                            inline
                        >
                            <v-list-item-title>Cereri</v-list-item-title>
                        </v-badge>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{name: 'expozanti'}" v-if="isAdmin && expozanti">
                    <v-list-item-icon>
                        <v-icon>mdi-list-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="transparent-badge">
                        <v-badge
                            :content="expozanti.length"
                            :value="expozanti.length"
                            color="transparent"
                            light
                            inline
                        >
                            <v-list-item-title>Expozanti</v-list-item-title>
                        </v-badge>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon color="red darken-3">mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>

</template>

<script>

import api from './mixins/api'

export default {
    components: {
    },
    mixins: [ api ],
    data: () => ({
    }),
    created () {
        this.setToken();
        this.checkLogin(this.token);
        this.getExpozant(this.token);
        this.getCurrentUser(this.token);
        this.setEvenimente(this.token);

        if (this.$route.matched.some(({ name }) => name === 'login')) {
            this.$router.push({ name: 'dashboard' });
        }

        this.getUnapprovedExhibitors(this.token);
        this.setExpozanti(this.token);

        setInterval(()=> {
            if (!this.$route.matched.some(({ name }) => name !== 'cereri' || name !== 'cont' || name !== 'expozanti')) {
                if (this.isAdmin) {
                    this.getUnapprovedExhibitors(this.token);
                    this.getExpozant(this.token);
                }
            }
        }, 5000);
    },
    mounted () {
    },
    computed: {
    },
    methods: {
    }
}

</script>

<style>
    .transparent-badge .v-badge__badge {
        color: black !important;
    }
    
    .max-width-80 {
        max-width: 80px !important;
    }

    .gap-24 {
        gap: 24px !important;
    }

    .v-data-table-header th {
        white-space: nowrap !important;
    }

    .v-btn {
        text-transform: none !important;
        letter-spacing: .5px !important;
    }
</style>