<template>
	<div>
		<div v-if="false"> COMPLETEAZA FORMULAR: {{$route.params.slugEvent}} - {{$route.params.slugExpozant}} - {{$route.params.idFormular}}</div>

		<v-btn @click="saveForm(currentForm, 1, $route.params.idFormular, token)" outlined color="primary" class="mr-2">
            Save
        </v-btn>
        <v-btn @click="saveForm(currentForm, 2, $route.params.idFormular, token)" outlined color="primary" class="mr-2">
            Trimite
        </v-btn>

		<TheForm v-if="currentForm" :form="currentForm" />
	</div>
</template>

<script>

import api from '../mixins/api'
// import agrariaForm_f4 from '../json/agriplanta-f1.json';
import TheForm from '@/components/TheForm.vue'

export default {
	mixins: [ api ],
	components: {
        TheForm
    },
	data: () => ({

    }),
    created () {
    	this.currentForm = null;
    	this.getForm(this.$route.params.idFormular, this.token);
    }
}

</script>

<style>

</style>