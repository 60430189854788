<template>
	<v-container>
        <v-row dense>
            <v-col>

            	<v-card flat color="#F4F7F4">
					<v-card-title>
						Lista expozanti

						<v-spacer></v-spacer>

						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Cauta expozant"
							single-line
							hide-details
						></v-text-field>

						<v-spacer></v-spacer>
					</v-card-title>

					<v-card-text>
		            	<v-data-table
		            		v-if="expozantiNeaprobati"
							:headers="headers"
							:items="expozantiNeaprobati"
							:search="search"
						>
							<template v-slot:item.logo="{ item }">
								<v-sheet width="96" class="pa-2" color="transparent">
									<v-img :src="`${apiPath}uploads/logos/${item.logo_url}`" contain :aspect-ratio="1/1"></v-img>
								</v-sheet>
							</template>

							<template v-slot:item.link="{ item }">
								<v-btn @click="aprobaExpozant(item.cui, token)" text small color="green darken-3">
		            				<v-icon color="green darken-2">
										mdi-check-circle
									</v-icon>
		            			</v-btn>

		            			<v-btn
		            				@click="deleteTrigger(item)"
									text
									small
									color="red darken-3"
								>
		            				<v-icon color="red darken-2">
										mdi-delete-forever
									</v-icon>
		            			</v-btn>
							</template>

							<template v-slot:item.adresa="{ item }">
								{{item.adresa}}
							</template>
						</v-data-table>

						<v-dialog
							v-if="deleteExpozantItem"
							v-model="deleteExpozantDialog"
							width="500"
						>
		            		<v-card>
								<v-card-title class="text-h5">
									Sterge expozant {{deleteExpozantItem.nume}}
								</v-card-title>

								<v-card-text>
									ATENȚIE! Ești pe cale să ștergi o cerere de aprobare pentru un expozant.
								</v-card-text>

								<v-divider></v-divider>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										text
										@click="deleteExpozantDialog = false"
									>
										Cancel
									</v-btn>
									<v-btn
										color="red"
										text
										@click="respingeExpozant(deleteExpozantItem.cui)"
									>
										Dezaproba
									</v-btn>
								</v-card-actions>
							</v-card>
		            	</v-dialog>
					</v-card-text>

				</v-card>

		    </v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios'
import api from '../mixins/api'

export default {
	mixins: [ api ],
    data: () => ({
    	deleteExpozantDialog: false,
    	deleteExpozantItem: null,
    	search: '',
		headers: [
			{ text: 'Logo', value: 'logo' },
			{ text: 'Nume companie', value: 'nume' },
			{ text: 'Persoana de contact', value: 'persoana_contact' },
			{ text: 'CUI', value: 'cui' },
			{ text: 'Adresa', value: 'adresa' },
			{ text: 'Telefon', value: 'nr_tel' },
			{ text: 'Email', value: 'email' },
			{ text: 'Actiuni', value: 'link' }
        ],
    }),
    created () {
        this.getUnapprovedExhibitors(this.token);
    },
    computed: {
	},
    methods: {
    	deleteTrigger (item) {
    		this.deleteExpozantDialog = true
    		this.deleteExpozantItem = item
    	}
	}
}
</script>

<style>
	.width-full {
		width: 100%;
	}
</style>